/* GENERAL */

html {
    scroll-behavior: smooth;
}

@font-face {
    font-family: 'Blogger Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/Blogger_Sans.otf') format('opentype');
}

@font-face {
    font-family: 'Blogger Sans';
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/Blogger_Sans-Italic.otf') format('opentype');
}

@font-face {
    font-family: 'Blogger Sans';
    font-style: bold;
    font-weight: 600;
    src: url('../fonts/Blogger_Sans-Bold.otf') format('opentype');
}

* {
    margin: 0;
    font-family: "Blogger Sans", sans-serif;
}

p {
    margin: 0;
}

body {
    padding-right: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.5rem;
}

h3 {
    font-size: 1rem;
}

footer a:hover {
    color: #FFF;
    text-decoration: underline;
}

@media (min-width: 768px) {
    .text-md-justify {
        text-align: justify !important;
    }
}

.table thead th {
    border: 0;
}

.table thead th{
    white-space: pre;
}

.table td,
.table th {
    border: 1.7px solid #dee2e6;
}
.table td{
    height: 80px !important;
}
.cursorPointer {
    cursor: pointer;
}

@media(max-width:767px) {
    h3 {
        font-size: 1.1rem !important;
    }

    h2 {
        font-size: 1.3rem !important;
    }

    h1 {
        font-size: 1.5rem !important;
    }
}

.banner h1 {
    font-size: 3.5rem !important;
    font-weight: 600 !important;
}

.banner h4 {
    font-size: 2rem !important;
}

.banner h5 {
    font-size: 1.5rem !important;
}


@media (min-width: 992px) {
    .container-modify {
        max-width: 1200px !important;
    }
}



/* COLORS */

.text_red {
    color: #132651;
}

.text_white {
    color: #ffffff;
}

.checked {
    color: orange;
}

.text_grey {
    color: #a8a8a8;
}

.text_green {
    color: #28A745 !important;
}

.text_yellow {
    color: #FFC107 !important;
}

.text_red {
    color: #9F111B !important;
}

.bg_blue {
    background-color: #132651;
}

.bg_grey {
    background-color: #f1f1f1;
}

.bg_black {
    background-color: #000000;
}

.bg_red {
    background-color: #9F111B;
}

header .bg-light {
    background-color: #F1F1F1 !important;
}

.text-dark {
    color: #132651;
}


/* Buttons */
.btn-primary {
    background-color: #9F111B;
    border-color: #9F111B;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    background-color: #7d0c14;
    border-color: #7d0c14;
}

.btn-primary.focus,
.btn-primary:focus {
    background-color: #9F111B;
    border-color: #9F111B;
    box-shadow: 0 0 0 0.2rem rgba(159, 17, 27, .5);
}

.btn-primary:hover {
    background-color: #7d0c14;
    border-color: #7d0c14;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(159, 17, 27, .5);
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #9e555a;
    border-color: #9e555a;
}

.btn-secondary {
    background-color: #9e555a;
    border-color: #9e555a;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
    background-color: #9e555a;
    border-color: #9e555a;
}

.btn-secondary.focus,
.btn-secondary:focus {
    background-color: #9e555a;
    border-color: #9e555a;
    box-shadow: 0 0 0 0.2rem rgba(159, 17, 27, .5);
}

.btn-secondary:hover {
    background-color: #9e555a;
    border-color: #9e555a;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #9e555ade;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #fff;
    background-color: #9e555a;
    border-color: #9e555a;
}

/* NAVBAR */
.dropdown-item.delete_profile:hover {
    background-color: #DC3545;
    color: #FFFFFF !important;
}

.dropdown-item.dropdown_link:hover {
    background-color: #DEE2E6;
    color: #000000 !important;
    border-radius: 0px !important;
}


/* input range */
.custom-range::-webkit-slider-thumb {
    background: #9F111B !important;
}

.custom-range::-moz-range-thumb {
    background: #9F111B !important;
}

.custom-range::-ms-thumb {
    background: #9F111B !important;
}

/* imput select */
select {
    border: 0 !important;
    border-bottom: solid 2px #ccc !important;
    border-radius: 0 !important;
}

select:focus {
    color: #495057;
    background-color: #fff;
    border-color: none !important;
    border-bottom: solid 2px #9F111B !important;
    outline: 0;
    box-shadow: none !important;
}

select:active {
    border: 0;
    border-bottom: solid 2px #9F111B !important;
    border-radius: 0 !important;
}

/* imput select */
input.form-control:focus {
    border-color: #9F111B;
    box-shadow: 0 0 0 0.2rem rgba(159, 17, 27, .5) !important;
}


/* header */
.introHeader {
    background-image: url("../images/page/headerImg.jpg");
    background-color: #cccccc;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 40rem;
}

.test::before {
    content: "";
    width: 212px;
    height: 100%;
    background: #9F111B;
    transform: skew(10deg);
    left: -60px;
    position: absolute;
}

.principal-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 280px;
    overflow: hidden !important;
}

.principal-logo>img {
    width: 100%;
}

.whatsapp {
    position: fixed;
    bottom: 20px;
    right: 15px;
    width: 70px;
    height: 70px;
    padding: 6px;
    border-radius: 100%;
    background-color: #128C7E;
    z-index: 100000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.whatsapp>img {
    width: 30px;
}

@media (max-width:992px) {
    .banner h1 {
        font-size: 3rem;
        font-weight: 600;
    }

    .banner h2 {
        font-size: 1.5rem;
    }

    .banner h3 {
        font-size: 1rem;
    }
}

/* HOME - STEPS */

.nav-item a:focus {
    outline: 0 !important;
}

.nav-pills a {
    border-radius: 50% !important;
    background-color: #EEEBEB;
    color: #9F111B;
    font-weight: 600;
    font-size: 1.6rem;
    padding-left: 19px;
    padding-right: 19px;
}

@media screen and (min-width: 768px) {
    .nav-pills a {
        font-size: 2rem;
        padding-left: 22px;
        padding-right: 22px;
    }
}

.nav-pills a.active,
.nav-pills a:hover {
    border-radius: 50% !important;
    background-color: #9F111B !important;
    color: #FFFFFF !important;
}

.nav-pills {
    background-repeat: repeat;
    background-size: contain;
    background-position: center;
    background-image: url(../images/icons/linea.png);

}

.tab-pane {
    height: 180px;
}

.bg_icon {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

@media (max-width:768px) {
    .bg_icon {
        background-size: 30%;
    }
}

.bg_icon.simula {
    background-image: url(../images/icons/vehicle_icon_grey.png);
}

.bg_icon.encontra {
    background-image: url(../images/icons/vehicle_icon_grey.png);
}

.bg_icon.reserva {
    background-image: url(../images/icons/contact_icon_grey.png);
}

.bg_icon.paga {
    background-image: url(../images/icons/contract_icon_grey.png);
}

.bg_icon.inscribi {
    background-image: url(../images/icons/calendar_icon_grey.png);
}

.bg_icon.retira {
    background-image: url(../images/icons/bill_icon_grey.png);
}

/* table */
/* @media (min-width:767px) {
    .table-responsive {
        overflow-x: hidden;
    }
}

@media (max-width:768px) {
    .table-responsive {
        overflow-x: auto;
    }
} */

.table td {
    padding: 0px 5px 0px 5px !important;
}

@media (max-width:992px) {
    .firstItemTable {
        color: #9F111B;
        font-weight: bold;
    }
}

.cell-responsive-table {
    border-bottom: solid 1px #DEE2E6 !important;
    border-left: solid 1px #DEE2E6 !important;
}

@media (max-width:992px) {
    .tableResponsive {
        border-left: solid 1px #DEE2E6 !important;
    }
}

/* STEPS */
.headerSteps {
    /* will also set the opacity of the link text */
    background: linear-gradient(rgba(255, 255, 255, .5), rgba(255, 255, 255, .5)), url("../images/page/woman_driving.jpg");
    /* The image used */
    background-color: #cccccc;
    /* Used if the image is unavailable */
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: cover;
    height: 500px;
}

/* LOGIN AND SIGN UP */

.google {
    background-color: #45A3E5 !important;
    opacity: 1 !important;
    box-shadow: none !important;
}

.google span {
    width: calc(100% - 55px);
    color: #FFFFFF !important;
    padding: 0 !important;
    font-size: 1rem;
}

.google span:hover {
    text-decoration: underline;
}

.google div {
    margin: 0 !important;
    padding: 8px !important;
}

.facebook {
    background-color: #004088;
    color: #FFFFFF;
    border-radius: 2px;
    padding-top: 0.55rem;
    padding-bottom: 0.55rem;
}

.facebook:hover {
    color: #FFFFFF;
    text-decoration: underline;
}

.facebook i {
    font-size: 2rem !important;
    position: absolute;
    left: 0.25rem;
    top: 50%;
    transform: translate(0%, -50%);
}

/* FOOTER */
.icon_footer {
    font-size: 2.5rem;
}

footer {
    background-color: #132651;
}

footer a,
footer p {
    color: #ffffff;
}

footer i {
    font-size: 2rem;
    color: #ffffff;
}

button.btn-outline-info.favorite {
    background-color: #1a3571 !important;
    color: #FFFFFF !important;
}

.container_admin_backend {
    min-height: 77vh;
}


.range {
    width: 100%;
}


.bubble {
    background: #9F111B;
    color: white;
    padding: 4px 12px;
    position: absolute;
    border-radius: 4px;
    bottom: 100%;
    left: 50%;
    width: 120px;
    transform: translateX(-50%);
    text-align: center;
}

.bubble::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 2px;
    background: #9F111B;
    left: 50%;
    bottom: -1px;
}

.range-input {
    height: auto;
    padding: 0 !important;
    text-align: center !important;
}

/* ----------------------OPORTUNIDADES 0KM------------------------------- */

.color-portunidades0km {
    color: #132651 !important;
}

.bg_0km {
    background: #132651 !important;
}

.bubble0km {
    background: #132651 !important;
    color: white;
    padding: 4px 12px;
    position: absolute;
    border-radius: 4px;
    bottom: 100%;
    left: 50%;
    width: 120px;
    transform: translateX(-50%);
    text-align: center;
}

.bubble0km::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 2px;
    background: #132651;
    left: 50%;
    bottom: -1px;
}

/* input range */
.range.opp0km::-webkit-slider-thumb {
    background: #132651 !important;
}

.range.opp0km::-moz-range-thumb {
    background: #132651 !important;
}

.range.opp0km::-ms-thumb {
    background: #132651 !important;
}

/* imput select */
select.opp0km {
    border: 0 !important;
    border-bottom: solid 2px #ccc !important;
    border-radius: 0 !important;
}

select.opp0km:focus {
    color: #495057;
    background-color: #fff;
    border-color: none !important;
    border-bottom: solid 2px #132651 !important;
    outline: 0;
    box-shadow: none !important;
}

select.opp0km:active {
    border: 0;
    border-bottom: solid 2px #132651 !important;
    border-radius: 0 !important;
}

/* imput select */
input.opp0km.form-control:focus {
    border-color: #132651;
    box-shadow: 0 0 0 0.2rem rgba(19, 38, 81, .5) !important;
}

.Opp0km .btn-primary.dropdown-toggle {
    background-color: #132651;
    border-color: #132651;
}

.Opp0km .btn-primary.dropdown-toggle:active {
    background-color: #0d1935;
    border-color: #132651;
}

.Opp0km .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(19, 38, 81, .5) !important;
}
.Opp0km_GoBack{
    background-color: #132651 !important;
    border-color: #132651 !important;
    color: white !important;
    padding: 5px;
    border-radius: 10px;
    position: fixed;
    bottom: 10px;
    right:10px;
}
/* ----------------------RUIZ AUTOMOTORES------------------------------- */

.color-ruizautomotores {
    color: #232628 !important;
}

.color-yellowRuiz {
    color: #FFC72C !important;
}

.bg_ruiz {
    background: #FFC72C !important;
}

.bg_ruiz_dark {
    background: #0B0B0C !important;
}

.bubbleRuiz {
    background: #FFC72C !important;
    color: #0B0B0C;
    padding: 4px 12px;
    position: absolute;
    border-radius: 4px;
    bottom: 100%;
    left: 50%;
    width: 120px;
    transform: translateX(-50%);
    text-align: center;
}

.bubbleRuiz::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 2px;
    background: #FFC72C;
    left: 50%;
    bottom: -1px;
}

/* input range */
.range.RuizMot::-webkit-slider-thumb {
    background: #FFC72C !important;
}

.range.RuizMot::-moz-range-thumb {
    background: #FFC72C !important;
}

.range.RuizMot::-ms-thumb {
    background: #FFC72C !important;
}

/* imput select */
select.RuizMot {
    border: 0 !important;
    border-bottom: solid 2px #ccc !important;
    border-radius: 0 !important;
}

select.RuizMot:focus {
    color: #495057;
    background-color: #fff;
    border-color: none !important;
    border-bottom: solid 2px #0B0B0C !important;
    outline: 0;
    box-shadow: none !important;
}

select.RuizMot:active {
    border: 0;
    border-bottom: solid 2px #0B0B0C !important;
    border-radius: 0 !important;
}

/* imput select */
input.RuizMot.form-control:focus {
    border-color: #0B0B0C;
    box-shadow: 0 0 0 0.2rem rgba(19, 38, 81, .5) !important;
}

.Rmotor .btn-primary.dropdown-toggle {
    background-color: #FFC72C;
    border-color: #FFC72C;
}

.Rmotor .btn-primary.dropdown-toggle:active {
    background-color: #a78a3d;
    border-color: #FFC72C;
}

.Rmotor .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 199, 44, .5) !important;
}
.Ruiz_GoBack{
    background-color: #FFC72C !important;
    border-color: #FFC72C !important;
    color: white !important;
    padding: 5px;
    border-radius: 10px;
    position: fixed;
    bottom: 10px;
    right:10px;
}

.loading-container {
    height: 600px;
}

/* React autosuggest */
.react-autosuggest__suggestions-container--open {
    position: absolute;
    z-index: 12;
    background-color: #FFFFFF;
}

.react-autosuggest__suggestions-container--open ul {
    list-style-type: none;
    padding: 0;
    max-height: 150px;
    overflow: auto;
    margin: 0;
}

.react-autosuggest__suggestions-container--open ul li {
    padding: 0.5rem;
    cursor: pointer;
    text-transform: uppercase;
}

.react-autosuggest__suggestions-container--open ul li:hover {
    text-decoration: underline;
}

.react-autosuggest__suggestions-container--open ul li:nth-child(odd) {
    background-color: rgba(26, 53, 113, 0.1);
}

.filter .react-autosuggest__container {
    flex: 0 0 100%;
    max-width: 100%;
}

.filter .form-control {
    border: none !important;
    border-bottom: 1px solid #ced4da !important;
}

.filter .form-control:focus {
    border: none !important;
    border-bottom: 1px solid #ced4da !important;
    box-shadow: none !important;
}

.share_link {
    color:#7d0c14;
}

.share_link_op0km {
    color: #132651;
}

.share_link_ruiz {
    color: #FFC72C;
}